<template>
<div id="token-store">
    <v-dialog scrollable v-model="dialog" persistent max-width="300" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-card-title>
                Nuevo token
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="token.name" label="Nombre" color="primary" :error-messages="nameErrors" placeholder="Nombre" persistent-placeholder hide-details="auto">
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="token.value" label="Valor" append-icon="mdi-refresh" :error-messages="valueErrors" @click:append="generateToken(80)" color="primary" placeholder="Valor" persistent-placeholder hide-details="auto">
                            <template #label>
                                Valor<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="grey darken-1" @click="$router.push('/configuracion')">Cancelar</v-btn>
                <v-btn text class="text-none" color="primary" @click="store" :disabled="$v.token.$invalid">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
export default {
    name: 'TokenStore',
    data: () => ({
        loading: false,
        dialog: true,
        token: {
            name: null,
            value: null
        }
    }),
    methods: {
        generateToken(length) {
            var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
            var charLength = chars.length;
            var result = '';
            for (var i = 0; i < length; i++) {
                result += chars.charAt(Math.floor(Math.random() * charLength));
            }
            this.token.value = result;
        },
        async store() {
            this.loading = true;
            await this.$axios.post('/api/token', this.token).then(response => {
                if (response.status == 201) {
                    this.$store.commit('token/INSERT_TOKEN', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { message: 'Token agregado.' });
                    this.$router.push('/configuracion');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.generateToken(80);
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (this.$v.token.name.$dirty) {
                if (!this.$v.token.name.required) {
                    errors.push('Este campo es obligatorio.');
                }
            }
            return errors;
        },
        valueErrors() {
            const errors = [];
            if (this.$v.token.value.$dirty) {
                if (!this.$v.token.value.required) {
                    errors.push('Este campo es obligatorio.');
                }
            }
            return errors;
        }
    },
    validations: {
        token: {
            name: {
                required
            },
            value: {
                required
            }
        }
    }
}
</script>
